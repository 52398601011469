var Transform = require('stream').Transform;
var util = require('util');

// TODO: also optimize for long patterns
function compile(pattern) {
  if (typeof pattern === 'string')
    pattern = new Buffer(pattern);
  if (!Buffer.isBuffer(pattern))
    throw new TypeError('expecting string or buffer');

  if (!pattern.length)
    throw new Error('expecting non-empty pattern');

  var body = 'return ';
  var l = pattern.length, n = l - 1;
  var first = 'chunk[index] === ' + pattern[n];

  // TODO: shouldn't need this condition
  if (pattern.length === 2)
    body += 'index && ';
  else if (pattern.length > 2)
    body += 'index > ' + (n - 1) + ' && ';

  body += first;

  for (var i = 1; i < l; i++)
    body += ' && chunk[index - ' + i + '] === ' + pattern[n - i];

  body += ';\n';
  return new Function('chunk', 'index', body);
}

function Chunker(transform, options) {
  if (!(this instanceof Chunker))
    return new Chunker(transform, options);

  Transform.call(this, {
    objectMode: false
  });

  this._readableState.objectMode = true;

  this.pattern = compile(options && options.pattern || '\r\n');
  this.transformer = transform;
  this._offset = 0;
  this._leftover = null;
}

util.inherits(Chunker, Transform);

Chunker.prototype._dispatch = function(blocks, callback) {
  var i = 0, n = blocks.length;
  if (this.transformer) {
    try {
      for (; i < n; i++) {
        this.push(this.transformer(blocks[i]));
      }
    } catch (err) {
      return callback(err);
    }
  } else {
    for (; i < n; i++) {
      this.push(blocks[i]);
    }
  }
  callback(null);
};

Chunker.prototype._transform = function(chunk, encoding, callback) {
  var offset = 0;
  var i = 0;

  if (this._leftover) {
    chunk = Buffer.concat([this._leftover, chunk], this._leftover.length + chunk.length);
    offset = this._offset;
    i = this._leftover.length;
    this._offset = 0;
    this._leftover = null;
  }

  var blocks = [];

  for (; i < chunk.length; i++)
    if (this.pattern(chunk, i))
      blocks.push(chunk.slice(offset, offset = i + 1));

  if (offset < chunk.length) {
    this._offset = offset;
    this._leftover = chunk;
  }

  this._dispatch(blocks, callback);
};

Chunker.prototype._flush = function(callback) {
  var remain = this._leftover && this._leftover.slice(this._offset);
  this._offset = null;
  this._leftover = null;
  this._dispatch([remain], callback);
};

module.exports = Chunker;
